$(function () {
    var lightboxItem = 0;
    var lightboxItems = [
    ];
    $(".carousel-item-inner .carousel-item-img").each(function () {
        lightboxItems.push({src: $(this).data('mediumUrl')});
    });

    initMagnificPopup();

    prestashop.on('updatedProduct', function (event) {
        lightboxItems = [];
        $(".carousel-item-inner .carousel-item-img").each(function () {
            lightboxItems.push({src: $(this).data('mediumUrl')});
        });
        initMagnificPopup();
    });

//    $('#blockcart-modal').modal();
    $('#content').on('click', '#carouselProductImages .carousel-item-img', function (event) {
        event.preventDefault();
        var src = $(this).data('mediumUrl');
        $('.product-cover img').attr('src', src);
        $('.product-cover a').attr('href', src);
        lightboxItem = $(this).data('itemNr');
    });

    function initMagnificPopup() {
        $('.popup-link').magnificPopup({
            type: 'image',
//            delegate: $('.popup-link'),
            gallery: {
                enabled: true
            },
            items: lightboxItems,
            callbacks: {
                open: function (item) {
                    this.goTo(lightboxItem);
                    console.log('magnific ev: ', $(this.ev[0]));
                    console.log('magnific lightboxItem:', lightboxItem);
                },
            }
        });
    }

});