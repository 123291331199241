import 'bootstrap';
//import 'popper.js';
import 'imagelightbox';
import ScrollReveal from 'scrollreveal';
//import TouchSpin from 'bootstrap-touchspin';
import './checkout.js';
import './facets.js';
import './cart';
import './product';
import 'magnific-popup';

$(function () {
//    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//        return new bootstrap.Popover(popoverTriggerEl)
//    })


    $('#notifications').on('click', '.btn-close', function () {
        var box = $(this).closest('.notification');
        box.remove();
    });

    $('#login-form, #customer-form').on('click', '.btn.show-hide-password', function () {
        var input = $(this).parent().find('input');
        if (input.attr('type') == 'password') {
            input.attr('type', 'text');
        } else {
            input.attr('type', 'password');
        }
        $(this).toggleClass('show hide');
    });

//    $('.lightbox').imageLightbox({
//        overlay: true,
//        arrows: true,
//        button: true
//    });

    $('.input-group-append').on('click', '.show-hide-password', function () {
        var input = $(this).parent().prev();
        if (input.attr('type') == 'password') {
            input.attr('type', 'text');
        } else {
            input.attr('type', 'password');
        }
        $(this).toggleClass('show hide');
    });

    window.sr = ScrollReveal({scale: .5,
        duration: 1000,
        reset: true});
    sr.reveal('.product-category-image');
    sr.reveal('.product-category-description');

    $('#quantity_group').on('click', '.btn-quantity', function () {
        var action = $(this).data('action');
        var value = parseInt($('#quantity_wanted').val());

        switch (action) {
            case 'subtract':
                if (value > 1) {
                    value = value - 1;
                    $('#quantity_wanted').val(value);
                }
                break;
            case 'add':
                value = value + 1;
                $('#quantity_wanted').val(value);
                break;
        }
        $('.product-prices .product-price .main-price').text(changeQuantity());
    });

    $('#quantity_group').on('change', '#quantity_wanted', function () {
        $('.product-prices .product-price .main-price').text(changeQuantity());
    })

    function changeQuantity() {
        var quantity = $('#quantity_wanted').val();
        var discounts = $('.product-prices .product-price').data('discounts');
        var price = $('.product-prices .product-price .main-price').text();
        $.each(discounts, function (index, value) {
            if (quantity >= value.from_quantity) {
                price = value.discount;
                return;
            }
        });
        return price;
    }

//    $('#quantity_wanted').TouchSpin(
//            {
////                verticalbuttons: true,
//                min: 1,
//                max: 1000,
//                verticalupclass: 'glyphicon glyphicon-plus',
//                verticaldownclass: 'glyphicon glyphicon-minus'
//            }
//    );


});