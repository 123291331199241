$(document).ready(() => {
    var blockcartModal = document.getElementById('blockcartModal');
    if (blockcartModal) {
        var cartModal = new bootstrap.Modal(blockcartModal);
    }

    var shoppingcartPreviewCollapse = document.getElementById('shoppingcart_preview');
    var bsCollapse = new bootstrap.Collapse(shoppingcartPreviewCollapse, {
        toggle: false
    });
    var pQuant = 0;

    $('#header').on('mouseenter', '.blockcart.cart-preview', function () {
        bsCollapse.show();
    });
    $('#header').on('mouseleave', '.blockcart.cart-preview', function () {
        bsCollapse.hide();
    });

    prestashop.on('updateCart', (event) => {
        var cart = event.resp.cart;
        var products = cart.products;
        var tableProductsLines = $('.shoppingcart-products .tbody-products');
        var counter = 0;
        $('#btn_shoppingcart_preview .subtotal-amount').text(cart.products_count);

        $('.shoppingcart-products .shoppingcart-products-item').remove();
        $('.cart-preview .no-products-info').remove();
        $.each(products, function (index, value) {
            $('.spacer-line').before(prepareRow(value));
            counter += 1;
        });
        setSummary(cart);
        $('.cart-preview .subtotal-amount').text(counter);
//        console.log('counter: ', counter);
        if (blockcartModal) {
            cartModal.show();
        }
    });

    $('#main').on('focus', '.cart-overview input.product-quantity', function () {
        pQuant = $(this).val();
    });

    $('#main').on('change', '.cart-overview input.product-quantity', function () {
        var id = $(this).data('productId');
        var attr = $(this).data('productAttribute');
        var updateUrl = $('.cart-overview.js-cart').data('updateUrl');
        var refreshUrl = $('.cart-overview.js-cart').data('refreshUrl');
        var qty = $(this).val();

        var quantityWanted = qty - pQuant;
        var op = quantityWanted > 0 ? 'up' : 'down';

        let query = {
            id_product: id,
            update: 1, //a4w
            id_product_attribute: attr, //a4w
            ajax: 1, //a4w
            id_customization: 0,
            //        group[1]: 1,
            qty: quantityWanted,
            op: op, //a4w
            action: 'update'
        };
        let actionURL = refreshUrl;

//        console.log('qty change: ', $(this).val());
        $.post(actionURL, query, null, 'json').then((resp) => {
            prestashop.emit('updateCart', {
                reason: {
                    idProduct: resp.id_product,
                    idProductAttribute: resp.id_product_attribute,
                    idCustomization: resp.id_customization,
                    linkAction: 'add-to-cart',
                    cart: resp.cart
                },
                resp: resp
            });
        }).fail((resp) => {
            console.log('error');
            prestashop.emit('handleError', {eventType: 'addProductToCart', resp: resp});
        });
    }
    );

    function prepareRow(product) {
        var name = $('<td class="product-name"><a href="' + product.url + '">' + product.name + '</a></td>');
        $.each(product.attributes, function (key, value) {
            name.append($('<div class="product-attributes"><span class="attr-label">' + key + ':</span><span class="attr-value">' + value + '</span></div>'));
        });
        var tr = $('<tr class="shoppingcart-products-item" id="prod_line_' + product.id + '"></tr>');
        tr.append($('<td class="img"><img src="' + product.cover.small.url + '"></td>'));
        tr.append(name);
        tr.append($('<td class="product-quantity">' + product.quantity + '</td>'));
        tr.append($('<td class="product-price">' + product.price + '</td>'));
        tr.append($('<td><a class="remove-from-cart btn btn-sm btn-outline-primary" rel="nofollow" href="' + product.remove_from_cart_url + '" data-link-action="remove-from-cart"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path><path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path></svg></a></td>'));
        return tr;
    }

    function setSummary(cart) {
//        console.log('cart: ', cart.subtotals);
        $('.shoppingcart-products .subtotal-line').remove();
        $.each(cart.subtotals, function (key, value) {
            if (value) {
                $('.spacer-line').after($('<tr class="' + value.type + ' subtotal-line"><td colspan="3" class="label">' + value.label + ': </td><td colspan="2" class="value">' + value.value + '</td></tr>'));
            }
        });
        $('.cart-total-tax-exl .label').text(cart.totals.total_excluding_tax.label);
        $('.cart-total-tax-exl .value').text(cart.totals.total_excluding_tax.value);
        $('.cart-total-tax-incl .label').text(cart.totals.total_including_tax.label);
        $('.cart-total-tax-incl .value').text(cart.totals.total_including_tax.value);
    }

    function getRefresh() {
        url = $('#shoppingcart_preview').data('refreshUrl');
        console.log('refreshUrl: ', url);
        $.ajax({
            url: url,
            data: {
                action: 'add-to-cart',
                id_product: 400,
                id_product_attribute: 0,
                id_customization: 0
            }
        }).done(function () {
        });
    }
    ;
});